import { useState, useEffect } from 'react';

export default function useConf() {
  const [conf, setConf] = useState(null);

  useEffect(() => {
    fetch("/conf.json", { headers: { Accept: "application/json" } })
      .then((res) => res.json())
      .then(json => {
        let data = {
          app: {
            backendApiUrl: json.BACKEND_API_URL
          },
          okta: {
            issuer: json.OKTA_ISSUER,
            clientId: json.OKTA_CLIENT_ID,
            scopes: ['openid', 'profile', 'email'],
            redirectUri: `${window.location.origin}/login/callback`
          }
        };
        setConf(data);
      })
      .catch(console.error);
  }, []);

  return conf;
}

