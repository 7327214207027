import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import './App.css';
import Tableau from './containers/Tableau';
import Login from './containers/Login';
import useConf from './conf';

function App() {

  const history = useHistory();
  const conf = useConf();

  const customAuthHandler = () => {
    history.push('/');
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '', window.location.origin));
  };

  if(!conf) return <p>Loading conf...</p>;

  const oktaAuth = new OktaAuth(conf.okta);

  return (
    <div className="App">
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
        <SecureRoute path="/tableau" render={() => <Tableau conf={conf} />} />
        <Route path="/" exact render={() => <Login conf={conf}/>} />
        <Route path="/login/callback" component={LoginCallback} />
      </Security>
    </div>
  );
}

export default App;
