import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useLocation } from 'react-router-dom';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

const Tableau = ({conf}) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [errorMsg, setErrorMsg] = useState(null);

    const query = useQuery();
    let view = query.get('v');

    let authorizationHeader = `Bearer ${authState.accessToken?.accessToken}`;

    useEffect(() => {

        fetch(`${conf.app.backendApiUrl}/ticket${view ? '?v=' + view : ''}`, {
            headers: {'Authorization': authorizationHeader}
        })
            .then(res => {
                if(res.ok)
                    return res.text();
                else
                    throw new Error(res.status);
            })
            .then(link => window.location.href = link)
            .catch(err => setErrorMsg('Failed to fetch tableauLink', err));
    }, [conf, view, authorizationHeader]);

    const handleLogout = async () => oktaAuth.signOut();

    if(!errorMsg) return <div><p>chargement...</p></div>;

    return (
        <div>
            <div>
                <p id="welcome">Vous vous êtes connecté, &nbsp;{authState.idToken?.claims?.name}!</p>
                <p><button id="logout-button" type="button" onClick={handleLogout}>Se déconnecter</button></p>
                {errorMsg && <p className="error-msg">{errorMsg}</p>}
            </div>
        </div>
    );
};

export default Tableau;